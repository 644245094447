import React, { lazy, Suspense } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { ReactSVG } from "react-svg";

import { useCustomer } from "context/customerContext";
import { useWindow } from "context/windowContext";

import useMyAccount from "hooks/myAccount/useMyAccount";

import MyAccountOrderItem from 'components/UI/myAccount/myAccountOrderItem';
import Loader from "components/UI/loader";
import Image from "components/UI/image";
import Button from "components/UI/button";

import { isEmpty, isNotEmpty } from "utils/helper";
import { t } from "utils/translate";

import DoctorAvatar from "images/doctor-avatar-sample.png";
import ArrowMobile from "images/svg/my-account-arrow-mobile.svg";
import ArrowDesktop from "images/svg/my-account-arrow-desktop.svg";
import ExclamationIcon from "images/svg/exclamation-light.svg";

import "styles/my-account.scss";

export const myAccountImageQuery = graphql`
  query MyAccountImageQuery {
    allContentfulBannerUpdated(
      filter: { location: { eq: "My account" }, type: { eq: "image" } }
    ) {
      nodes {
        image {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
      }
    }
  }
`;

const MyAccountOrder = lazy(() => import('components/UI/myAccount/myAccountOrder'));
const MyAccountOrders = lazy(() => import('components/UI/myAccount/myAccountOrders'));
const MyAccountProfile = lazy(() => import('components/UI/myAccount/myAccountProfile'));

const MyAccountContent = () => {
    const queryData = useStaticQuery(myAccountImageQuery);
    const myAccountImage = queryData.allContentfulBannerUpdated.nodes[0].image;

    const { customer, removeCustomer } = useCustomer();
    const { location } = useWindow();
    const {
        hidePopup,
        isShowUpdateForm,
        editCustomerHandler,
        chatNameMap,

        showPopUp,
        setShowPopUp,
        popUpMessage,
        setPopUpMessage,
        editPopUpMessage,
        selectedTitle,
        setSelectedTitle,

        orders,
        isOrdersLoading,

        ordersCountToShow,
        showMore,
        showLess,

        customerDetails,
        setCustomerDetails,

        links,
        products,
        setIsShowUpdateForm,
        activeOrderPageID,
        setActiveOrderPageID,

        getAddresses,
        addresses,
        isAddressLoading,

        tickets,
        isTicketsLoading,
        treatmentStatuses,
        treatmentStatusesForAction,
        countries,
        getCountries,

        unreadMessages,
        unreadMessagesCount,
        isUnreadMessagesLoading,

        tabToShow,
        setTabToShow,

        resetPasswordData,
        setResetPasswordData,
        editCustomerPassword,

        isShowReset,
        setIsShowReset,
    } = useMyAccount();

    const currentOrder = orders.find((order) => order.id === activeOrderPageID);

    const sortedOrders = [...orders].sort((a, b) => {
        return Date.parse(b.date_modified) - Date.parse(a.date_modified);
    });

    const ordersToShow = sortedOrders.slice(0, ordersCountToShow);
    const myAccountOrdersToShow = sortedOrders.slice(0, 3);

    const tabsRenderMap = {
        orders: (
            <MyAccountOrders
                ordersToShow={ordersToShow}
                setActiveOrderPageID={setActiveOrderPageID}
                products={products}
                orders={orders}
                ordersCountToShow={ordersCountToShow}
                showMore={showMore}
                showLess={showLess}
                setIsShowOrdersTab={setTabToShow}
                isOrdersLoading={isOrdersLoading}
            />
        ),
        profile: (
            <MyAccountProfile
                setIsShowOrdersTab={setTabToShow}
                setIsShowUpdateForm={setIsShowUpdateForm}
                customerDetails={customerDetails}
                addresses={addresses}
                countries={countries}
                getAddresses={getAddresses}
                getCountries={getCountries}
                isShowUpdateForm={isShowUpdateForm}
                hidePopup={hidePopup}
                editCustomerHandler={editCustomerHandler}
                setCustomerDetails={setCustomerDetails}
                resetPasswordData={resetPasswordData}
                setResetPasswordData={setResetPasswordData}
                editCustomerPassword={editCustomerPassword}
                isShowReset={isShowReset}
                setIsShowReset={setIsShowReset}
                isAddressLoading={isAddressLoading}
            />
        ),
    };

    if (!customer) {
        navigate("/");
        return <></>;
    }

    if (currentOrder) {
        return (
            <Suspense fallback={<Loader/>}>
                <MyAccountOrder
                    setActiveOrderPageID={setActiveOrderPageID}
                    activeOrderPageID={activeOrderPageID}
                    orderData={currentOrder}
                    products={products}
                />
            </Suspense>
        );
    }

    if (tabsRenderMap[tabToShow]) {
        return (
            <Suspense fallback={<Loader/>}>
                {tabsRenderMap[tabToShow]}
            </Suspense>
        );
    }

    if ((new URLSearchParams(location.search)).get("orderId")) {
        return <Loader/>
    }

    const hideAndResetPopUp = () => {
        setShowPopUp(false);
        setPopUpMessage({
            type: "",
            question: "",
        });
    };

    const handleButtonClick = (title) => {
        setSelectedTitle(title);
        setShowPopUp(true);
    };

    return (
        <div className="my-account__content">
            <div className="order-block">
                <h2 className="title">Overzicht</h2>
                <div className="messages">
                    {
                        isEmpty(unreadMessages) ? (<>
                            <div className="messages__title">
                                <p className="messages__title--title">Berichten</p>
                            </div>
                            {
                                isUnreadMessagesLoading ? (
                                    <Loader/>
                                ) : (
                                    <div className="messages__message">
                                        <div className="messages__message--wrapper">
                                            <p className="messages__message--problem">
                                                Geen berichten
                                            </p>
                                            <p className="messages__message--doctor">
                                                Je kan een gesprek starten met een zorgverlener door een behandeling
                                                te starten.
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                        </>) : (<>
                            <div className="messages__title">
                                <p className="messages__title--title">Berichten </p>
                                <span className="messages__title--count typography__sub-text">
                                    {unreadMessagesCount}
                                </span>
                            </div>
                            {Object.entries(unreadMessages).map((item) => (
                                <Link
                                    key={item[0]}
                                    to={`/my-account/chat?chatId=${item[0]}`}
                                    className="messages__message"
                                >
                                    <Image
                                        className="doctor-avatar"
                                        src={item[1].admin.photo?.content_url || DoctorAvatar}
                                        alt="Doctor"
                                    />
                                    <div className="messages__message--wrapper">
                                        <p className="messages__message--problem">
                                            {chatNameMap[item[0]]}
                                        </p>
                                        <p className="messages__message--doctor">
                                            {item[1].admin.name}
                                        </p>
                                    </div>
                                    <span className="messages__title--count typography__sub-text">
                                            {item[1].count}
                                        </span>
                                    <Image className="mobile-icon" src={ArrowMobile}/>
                                    <Image className="desktop-icon" src={ArrowDesktop}/>
                                </Link>
                            ))}
                        </>)
                    }
                </div>

                <div className="therapy">
                    <div className="therapy__title">
                        <p className="subtitle">Behandelingen </p>
                        {
                            (!isTicketsLoading && treatmentStatusesForAction === true) && (
                                <span className="therapy__title--message">
                                    <Image className="therapy__title--message--exclamation" src={ExclamationIcon}/>
                                    Actie vereist
                                </span>
                            )
                        }
                    </div>

                    {
                        isTicketsLoading ? (
                            <Loader/>
                        ) : (
                            isEmpty(treatmentStatuses) ? (
                                <div className="therapy__block">
                                    <p className="messages__message--problem">
                                        Geen actieve behandelingen
                                    </p>
                                    <p className="messages__message--doctor">
                                        Start een behandeling door een medische vragenlijst in te vullen op het Wellis
                                        platform.
                                    </p>
                                </div>
                            ) : (
                                Object.entries(treatmentStatuses).map(([key, treatmentStatus]) => (
                                    <Link key={key}
                                          className="therapy__block"
                                          to={`/my-account/treatments?treatmentId=${key}`}>
                                        <p className="messages__message--problem">
                                            {chatNameMap[key]}
                                        </p>
                                        <p className="messages__message--doctor">
                                            {t(treatmentStatus.status)}
                                        </p>
                                        <ReactSVG className="mobile-icon" src={ArrowMobile}/>
                                        <ReactSVG className="desktop-icon" src={ArrowDesktop}/>
                                    </Link>
                                ))
                            )
                        )
                    }
                </div>

                <div className="orders">
                    <p className="subtitle">Bestellingen</p>

                    {
                        isOrdersLoading ? (
                            <Loader/>
                        ) : (
                            isNotEmpty(orders) ? (<>
                                {myAccountOrdersToShow.map((order) => (
                                    <MyAccountOrderItem
                                        key={order.id}
                                        setActiveOrderPageID={setActiveOrderPageID}
                                        id={order.id}
                                        products={products}
                                        dateCreated={order.date_created}
                                    />
                                ))}
                                {orders.length > 3 && orders.length > ordersCountToShow && (
                                    <button
                                        className="see-all"
                                        onClick={() => setTabToShow("orders")}
                                    >
                                        Al mijn bestellingen
                                    </button>
                                )}
                            </>) : (
                                <div className="orders__order">
                                    <div>
                                        <p className="messages__message--problem">
                                            {t("You have no orders yet")}
                                        </p>
                                        <p className="messages__message--doctor">
                                            {t("You need to purchase product to see your orders.")}
                                        </p>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>

                <div className="profile_account">
                    <p className="subtitle">Account</p>
                    {
                        links.map((item, index) => (
                            item.title === "Account" && (
                                <button
                                    onClick={item.onClick}
                                    key={index}
                                    className="profile_account_button"
                                >
                                    <div>
                                        <p className="messages__message--problem">Gegevens bekijken en wijzigen</p>
                                        <Image className="mobile-icon" src={ArrowMobile}/>
                                        <Image className="desktop-icon" src={ArrowDesktop}/>
                                    </div>
                                </button>
                            )
                        ))
                    }
                </div>

                <button onClick={removeCustomer} className="logout logout-desktop">
                    Afmelden
                </button>
            </div>
            <div className="nav-block">
                <h2 className="nav-title">Ondersteuning</h2>
                {
                    myAccountImage && (
                        <div className="support">
                            <Image image={myAccountImage} className="support__image"/>
                        </div>
                    )
                }
                <div className="service">
                    <p className="service__description">
                        Voor niet medische vragen kan je bij onze <Link to="" onClick={() => handleButtonClick("klantendienst")}>klantendienst</Link> terecht
                    </p>
                </div>

                <div className="links">
                    {links.map((item, index) => (
                        <button
                            onClick={() => handleButtonClick(item.title)}
                            name={item.title}
                            className="links__item"
                            key={index}
                        >
                            <p>{item.title}</p>
                            <Image className="mobile-icon" src={ArrowMobile}/>
                            <Image className="desktop-icon" src={ArrowDesktop}/>
                        </button>
                    ))}
                    {showPopUp && (
                        <div
                            onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                    hideAndResetPopUp();
                                }
                            }}
                            className="popup-wrapper"
                        >
                            <div className="popup-wrapper__content">
                                <p className="messages__message--problem">Hoe kunnen we je helpen met
                                    <span> {selectedTitle}?</span>
                                </p>
                                <form
                                    onSubmit={editPopUpMessage}
                                    className="popup-wrapper__content__form popup-wrapper__content__form--message"
                                >
                                    <textarea
                                        placeholder="..."
                                        name="question"
                                        wrap="soft | hard"
                                    />
                                    <textarea name="type" value={selectedTitle} className='textarea-hidden'/>
                                    <div className="buttons">
                                        <Button value="Verzenden" type="dark" isSubmit/>
                                        <Button value="Annuleer" type="light" onClick={hideAndResetPopUp}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    <button onClick={removeCustomer} className="logout logout-mobile">
                        Afmelden
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MyAccountContent;
