import React from "react";

import Main from "components/Layout/main";
import MyAccountContent from "components/UI/myAccount/myAccountContent";
import StaticPageMeta from "../components/UI/staticPageMeta";

import "styles/my-account.scss";


const MyAccount = (props) => {
    const gtmData = {
        page: {
            title: "My Account",
            type: "Account",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="My Account"/>
            <Main className="my-account" gtmData={gtmData}>
                <MyAccountContent/>
            </Main>
        </>
    )
};
export default MyAccount;
