import React from "react";

import useMyAccountOrderItem from "hooks/myAccount/useMyAccountOrderItem";
import { isEmpty } from "utils/helper";

import Loader from "components/UI/loader";
import Image from "components/UI/image";

import Thumbnail from "images/thumbnail-sample.png";
import ArrowMobile from "images/svg/my-account-arrow-mobile.svg";
import ArrowDesktop from "images/svg/my-account-arrow-desktop.svg";

const MyAccountOrderItem = (props) => {
    const { id, setActiveOrderPageID, products, dateCreated } = props;

    const {
        product,
        addMonthsToDate,
        interval,
        currProduct,
        isProductLoading
    } = useMyAccountOrderItem({ id, products });

    return (
        isProductLoading ? (
            <Loader/>
        ) : (
            isEmpty(product) ? (
                <></>
            ) : (
                <div onClick={() => setActiveOrderPageID(id)} className="orders__order">
                    <Image
                        className="orders__order--thumbnail"
                        alt="Thumbnail"
                        src={currProduct.images.find((image) => image.is_thumbnail)?.url_standard || Thumbnail}
                    />
                    <div>
                        <p className="messages__message--problem">{product?.name}</p>
                        <p className="messages__message--doctor">
                            verwacht in 2-3 werkdagen
                        </p>
                    </div>
                    <Image className="mobile-icon" src={ArrowMobile}/>
                    <Image className="desktop-icon" src={ArrowDesktop}/>
                </div>
            )
        )
    );
};

export default MyAccountOrderItem;
